<template>
  <div class="row m-0" :class="{'justify-content-center': align === 'center', 'justify-content-start': align === 'left', 'justify-content-end': align === 'right'}">
    <div class="col-auto p-0">
      <div class="icon_holder" :class="[size]" :style="{'background-color': bg}">
        <div class="row m-0 h-100 align-items-center justify-content-center text-center roundicon">
          <slot></slot>
        </div>
      </div>
      <div class="icon_text text-center" v-if="name">{{name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconRound',
  props: ['name', 'icon', 'size', 'align', 'bg']
}
</script>

<style scoped>
  .icon_holder {
    background-color: var(--green-color-dark);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    font-size: 28px;
    color: var(--gold-color);
    text-align: center;
  }
  .lightgreen .icon_holder {
    background-color: var(--green-color);
  }
  .icon_holder:hover {
    background-color: var(--green-color) !important;
  }
  .icon_holder.small {
    width: 45px;
    height: 45px;
    font-size: 21px;
  }
  .icon_holder.xsmall {
    width: 35px;
    height: 35px;
    font-size: 16px;
    background-color: var(--green-color) ;
  }
  .icon_holder.big {
    width: 70px;
    height: 70px;
    font-size: 35px;
  }
  .icon_holder.xbig {
    width: 70px;
    height: 70px;
    font-size: 35px;
  }
  .icon_text {
    font-weight: bold;
    font-size: 12px;
    color: var(--gold-color);
  }
  @media (min-width: 992px) {
    .icon_holder {
      background-color: var(--green-color-dark);
    }
    .icon_holder.xbig {
      width: 90px;
      height: 90px;
      font-size: 45px;
    }
  }
</style>
